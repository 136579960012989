<template lang="pug">
.more-or-less
  .more-or-less-content(ref="expandable" :class="contentClasses")
    slot(v-if="expanded")
  .more-or-less-bottom.d-flex.justify-content-center(v-if="!hideToggler && !fix")
    .d-flex.cursor-pointer.align-items-center
      component(:is="iconName" size="2em")
      .more-or-less-bottom-text(@click="toggle") {{ expanded ? $t('lessOptions') : $t('moreOptions') }}
</template>

<script>
  import { UilAngleDown, UilAngleUp } from '@iconscout/vue-unicons';
  import expandMixin from '@/components/Editor/mixins/expand';

  export default {
    name: 'MoreOrLess',
    components: { UilAngleDown, UilAngleUp },
    mixins: [expandMixin],
    props: {
      parentExpanded: {
        type: Boolean,
        default: null,
      },
      hideToggler: {
        type: Boolean,
        default: false,
      },
    },
    data: () => ({ expanded: false }),
    computed: {
      iconName() {
        return this.expanded ? 'uil-angle-up' : 'uil-angle-down';
      },
      contentClasses() {
        if (this.fix) return 'h-100';
        return '';
      },
    },
    mounted() {
      if (this.fix) this.expanded = true;
    },
    methods: {
      toggle() {
        if (this.fix) return;
        this.expanded = !this.expanded;
      },
    },
  };
</script>

<style lang="sass">
  @import '@/sass/variables/_colors.sass'

  .more-or-less
    color: $om-dark-grey-3
    &-content
      transition: opacity 150ms ease-out
      display: grid
      gap: 12px
      &.h-100
        opacity: 1
</style>
